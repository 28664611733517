import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";

const HeroSectionWrapper = styled.section`
  margin-bottom: 3rem;
  height: 45vh;
  min-height: 50rem;
  max-height: 60rem;
  display: flex;
  position: relative;
`;

const HeroImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  position: relative;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.8);

  @media (min-width: 768px) {
    background: linear-gradient(
      80deg,
      rgba(0, 0, 0, 0.95),
      rgba(0, 0, 0, 0.65),
      rgba(0, 0, 0, 0)
    );
  }
`;

const HeroSection = styled.div`
  color: var(--white);
  display: flex;
  justify-content: center;
  text-align: center;
  padding-bottom: 7.2rem;
  padding-left: 3.2rem;
  padding-right: 3.2rem;
  padding-top: 12.5rem;
  margin: auto;
  position: relative;
  z-index: 0;
  align-items: center;
  height: 90%;

  @media (min-width: 768px) {
    text-align: left;
    justify-content: flex-start;
    padding-top: 15rem;
    padding-right: 2.4rem;
    padding-left: 2.4rem;
    max-width: var(--max-width);
    height: auto;
    align-items: flex-start;
  }

  @media (min-width: 1024px) {
    justify-content: space-between;
  }
`;

const HeroSectionText = styled.div`
  .button-separation {
    margin-bottom: 2.5rem;
  }

  @media (min-width: 768px) {
    width: 65%;
    margin: 0px;
    max-width: 70rem;
    padding-left: 2rem;
  }

  h1 {
    text-transform: ${(props) =>
      props?.$isTitleUpperCase ? "uppercase" : "none"};
    font-size: 2.8rem;

    @media (min-width: 768px) {
      font-size: 3.8rem;
    }
  }

  p {
    font-size: 1.4rem;

    @media (min-width: 768px) {
      font-size: 1.8rem;
    }
  }
`;

const HeroSectionImage = styled.div`
  display: none;
  visibility: hidden;

  @media (min-width: 1024px) {
    display: block;
    width: 35rem;
    height: 40rem;
    position: relative;
    margin-right: 7rem;
    margin-top: -5rem;
    visibility: visible;
    z-index: 1;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      border: 2px solid var(--white);
      z-index: 0;
      top: 20px;
      left: 20px;
    }
  }
`;

const HeroImageBGWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  min-height: 40rem;
  max-height: 60rem;
  background-color: var(--black);
  z-index: -1;
  position: absolute;
  top: 0px;
  left: 0px;

  .gatsby-image-wrapper {
    width: 100%;

    img {
      object-position: right;

      @media (min-width: 768px) {
        object-position: center;
      }
    }
  }
`;

export default function HeroSectionComponent({
  titleUpperCase = false,
  title,
  text,
  link,
  linkText,
  heroImage,
  heroBackgroundImage,
}) {
  return (
    <HeroSectionWrapper>
      <HeroImageWrapper>
        <HeroSection>
          <HeroSectionText $isTitleUpperCase={titleUpperCase}>
            <div className='button-separation'>
              <h1>{title}</h1>
              <p>{text}</p>
            </div>
            {link && (
              <Link to={link} className='red-button'>
                {linkText}
              </Link>
            )}
          </HeroSectionText>
          {heroImage && (
            <HeroSectionImage>
              <GatsbyImage image={heroImage} alt='' />
            </HeroSectionImage>
          )}
        </HeroSection>
      </HeroImageWrapper>
      <HeroImageBGWrapper role='presentation'>
        <GatsbyImage image={heroBackgroundImage} alt='' />
      </HeroImageBGWrapper>
    </HeroSectionWrapper>
  );
}
