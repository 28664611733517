import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import styled from "styled-components";

const ProductRangeWrapper = styled.div`
  background-color: var(--white);
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
  bottom: 0px;
  left: 0;
  padding: 2rem 2rem 8rem;
  position: fixed;
  width: 100%;
  z-index: 2;

  @media (min-width: 1024px) {
    box-shadow: none;
    padding: 0px 0px 0px 2rem;
    position: relative;
    width: 35rem;
  }

  @media (min-width: 1366px) {
    width: 24rem;
  }

  .sidebar-sticky {
    @media (min-width: 1366px) {
      top: 10rem;
      position: sticky;
    }
  }

  h4 {
    font-size: 2.4rem;
    text-transform: uppercase;
  }

  ul {
    list-style: none;
    margin: 2rem 0px 2rem;
    padding: 0px;
    text-transform: uppercase;
    font-size: 1.4rem;
    display: flex;
    overflow: auto;

    @media (min-width: 768px) {
      margin-bottom: 0px;
    }

    @media (min-width: 1024px) {
      flex-wrap: wrap;
      flex-direction: column;
    }
  }

  li {
    display: flex;
    margin-bottom: 2rem;
    margin-top: 0.6rem;
    margin-right: 0.6rem;

    @media (min-width: 1024px) {
      margin-bottom: 0px;
      margin-right: 0px;
    }
  }

  a {
    align-items: center;
    background-color: var(--grey-dark);
    border-radius: var(--border-small);
    color: var(--black);
    display: flex;
    width: 100%;
    font-family: var(--font-oswald-bold);
    padding-bottom: 0.2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.2rem;
    transition: background-color 0.4s ease, color 0.4s ease;
    white-space: nowrap;
    height: 4rem;

    @media (min-width: 768px) {
      border-radius: var(--border-regular);

      &:hover {
        background-color: var(--black);
        color: var(--white);

        span {
          background-color: var(--grey-dark);
          color: var(--black);
        }
      }
    }

    &[aria-current="page"] {
      background-color: var(--black);
      color: var(--white);

      span {
        background-color: var(--grey-dark);
        color: var(--black);
      }
    }
  }

  span {
    transition: background-color 0.4s ease, color 0.4s ease;
    background-color: var(--white);
    display: flex;
    border-radius: var(--border-small);
    width: 2.4rem;
    height: 2rem;
    align-items: center;
    justify-content: center;
    margin-right: 0.4rem;

    @media (min-width: 768px) {
      width: 4rem;
      margin-right: 1rem;
      height: 4rem;
    }
  }
`;

function countRangeInProduct(products) {
  const counts = products
    .map((product) => product.product_range)
    .flat()
    .reduce((acc, range) => {
      const existingRange = acc[range.id];
      if (existingRange) {
        existingRange.count += 1;
      } else {
        acc[range.id] = {
          id: range.id,
          name: range.range_name,
          count: 1,
          slug: range.range_slug.current,
        };
      }
      return acc;
    }, {});

  const sortedRange = Object.values(counts).sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
  return sortedRange;
}

export default function ProductRangeFilter() {
  const { products } = useStaticQuery(graphql`
    query {
      products: allSanityProduct {
        nodes {
          product_range {
            id
            range_name
            range_slug {
              current
            }
          }
        }
      }
    }
  `);

  const rangeWithCounts = countRangeInProduct(products.nodes);
  return (
    <ProductRangeWrapper>
      <div className='sidebar-sticky'>
        <h4>Product Ranges:</h4>
        <ul>
          <li>
            <Link to='/our-products'>
              all
            </Link>
          </li>
          {rangeWithCounts.map((range) => (
            <li key={range.id}>
              <Link to={`/our-products/${range.slug}`}>
                {range.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </ProductRangeWrapper>
  );
}
