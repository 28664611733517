import React from "react";
import { Link, graphql } from "gatsby";
import { useLocation } from "@reach/router";
import styled from "styled-components";
import HeroSectionComponent from "../components/HeroSectionComponent";
import ContentWithSideBar from "../components/ContentWithSideBar";
import ProductCard from "../components/ProductCard";
import ProductRangeFilter from "../components/ProductRangeFilter";

import Seo from "../components/SEO";

const ProductList = styled.ul`
  list-style: none;
  padding: 0px;
  margin: 0px;

  li {
    margin-bottom: 2rem;
  }

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 2rem;
    row-gap: 2rem;
    width: 100%;
    max-width: 80rem;
    grid-template-rows: auto 1fr;
    grid-auto-flow: dense;
  }
`;

const NoProductWrapper = styled.div`
  flex: 1;
  padding: 2rem;
  background: var(--grey-dark);
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  h4 {
    margin-bottom: 1rem;
  }
`;

export default function OurProducts({ data, pageContext }) {
  const products = data.products.nodes;

  return (
    <>
      <Seo
        title={
          pageContext.name
            ? `Product range: ${pageContext.name}`
            : "All product ranges"
        }
        description={data.ourProductsPage.hero_text}
        location={useLocation()}
      />
      <HeroSectionComponent
        title={data.ourProductsPage.hero_title}
        text={data.ourProductsPage.hero_text}
        heroImage={false}
        isProductPage={true}
        heroBackgroundImage={
          data.ourProductsPage.hero_background_image.asset.gatsbyImageData
        }
      />
      <ContentWithSideBar isColReverse={true}>
        {products.length === 0 ? (
          <NoProductWrapper>
            <h4>No product found</h4>
            <Link to='/our-products' className='black-button'>
              view all of our products
            </Link>
          </NoProductWrapper>
        ) : (
          <ProductList>
            {products.map((product) => (
              <li key={product.id}>
                <ProductCard
                  itemImage={product.product_image.asset.gatsbyImageData}
                  itemLink={product.product_slug.current}
                  itemBrand={product.product_brand.brand_name}
                  itemTitle={product.product_name}
                  itemChinese={product.product_chinese_name}
                  itemCode={product.product_code}
                  itemInfo={product.product_info}
                  borderColor='var(--grey-light)'
                  smallVersion={false}
                />
              </li>
            ))}
          </ProductList>
        )}

        <ProductRangeFilter />
      </ContentWithSideBar>
    </>
  );
}

export const query = graphql`
  query ProductQuery($name: [String]) {
    products: allSanityProduct(
      filter: { product_range: { range_name: { in: $name } } }
    ) {
      nodes {
        id
        product_slug {
          current
        }
        product_name
        product_chinese_name
        product_brand {
          brand_name
        }
        product_code
        product_info

        product_image {
          asset {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }

    ourProductsPage: sanityOurProductsPage {
      hero_text
      hero_title
      hero_background_image {
        asset {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  }
`;
