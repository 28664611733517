import React from "react";
import styled from "styled-components";

const ContentWithSideBarWrapper = styled.section`
  display: flex;
  width: var(--width-percentage);
  margin-left: auto;
  margin-right: auto;
  padding: 1.8rem 2rem;
  flex-direction: ${(props) =>
    props?.$isColReverse ? "column-reverse" : "column"};
  margin-bottom: 5rem;

  @media (min-width: 768px) {
    justify-content: space-between;
    text-align: left;
    padding-left: 0px;
    padding-right: 0px;
    max-width: var(--max-width);
  }

  @media (min-width: 1024px) {
    flex-direction: row;
  }
`;

export default function ContentWithSideBar({ children, isColReverse = false }) {
  return (
    <ContentWithSideBarWrapper $isColReverse={isColReverse}>
      {children}
    </ContentWithSideBarWrapper>
  );
}
